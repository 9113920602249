/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate ,useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { currentConfig } from "amplifyConfig";
import { useCookies } from "react-cookie";
import { useAudit } from "./useAudit";
import { useConfig } from "./useConfig";
import dayjs from "dayjs"
import { getDomainName } from "../utils/getDomainName";
import { urls } from '../urls'
import { useLocalStorage } from "common";

console.log(currentConfig); // DO NOT REMOVE THIS LINE

type AuthContextType = {
  login?: Function;
  logout?: Function;
  handleMFASubmit?: Function;
  token?: string;
  user?: any;
  mfa?: any;
  authCode?: any;
  userForgotPassword?: boolean;
  handleResetPassword?: Function;
  forgotPassword?: Function;
  forceResetPassword?: boolean;
  sendForgotPassCode?: Function;
  authFlowError?: string;
  resetMFA?: Function;
  resetForceResetPassword?: Function;
  resetForgotPassword?: Function;
  setToken?: Function;
};

const AuthContext = createContext<AuthContextType>({});

interface Props {
  children?: ReactNode;
  userData: any;
  // any props that come into the component
}

export const AuthProvider = ({ children, ...props }: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const {token} = cookies;
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [authCode, setAuthCode] = useState({});
  const [mfa, setMFA] = useState(false);
  const [forceResetPassword, setForceResetPassword] = useState(false);
  const [userForgotPassword, setForgotPassword] = useState(false);
  const [authFlowError, setAuthFlowError] = useState("");
  const [, setLoggedIn, loggedIn] = useLocalStorage("loggedIn", false);
  const { addAuditEvent } = useAudit();
  const authUrl = urls.AUTH;


  const expDate = useMemo(() => {
    let date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }, []);

  const login = async (loginData: any) => {
    const userName = loginData.userName.trim();
    const password = loginData.password.trim();

    try {
      const userSession = await Auth.signIn(userName, password);
      console.log('debug token refresh userSession: ', userSession)
      setUser(userSession);
      return userSession;
      
    } catch (err: any) {
      return { errorMessage: err.message };
    }
  };

  useEffect(() => {
    console.log('debug token refresh user updated: ', user)
    if (user) {
      const { challengeName } = user;
      const { prefferedMFA } = user;
      setAuthCode("");
      const setupMFA = async () => {
        try {
          const res = await Auth.setupTOTP(user);
          const code =
            "otpauth://totp/AWSCognito:" +
            user.username +
            "?secret=" +
            res +
            "&issuer=Cognito";
          setAuthCode({ auth: code, text: res });
          setMFA(true);
        } catch (e: any) {
          setAuthFlowError(e.message);
        }
      };

      if (challengeName === "MFA_SETUP" || !challengeName) {
        setupMFA();
        console.log("challengeName", challengeName);
      } else if (challengeName === "SOFTWARE_TOKEN_MFA") {
        setMFA(true);
        console.log("challengeName", challengeName);
      } else if (challengeName === "NEW_PASSWORD_REQUIRED") {
        setForceResetPassword(true);
        console.log("challengeName", challengeName);
      } else if (prefferedMFA === "NOMFA") {
        console.log("challengeName", challengeName);
        console.log("prefferedMFA", prefferedMFA);
      }
    }
  }, [user]);

  const sendForgotPassCode = async (userName: string) => {
    try {
      const res = await Auth.forgotPassword(userName);
      if (res) forgotPassword();
      console.log("forgot password res: ", res);
    } catch (e: any) {
      setAuthFlowError(e.message);
    }
  };

  const forgotPassword = async () => {
    setForgotPassword(true);
  };

  const handleResetPassword = useCallback(
    async (newPass: string, code = null, userName: string) => {
      try {
        if (!code) {
          let res = await Auth.completeNewPassword(user, newPass, {
            given_name: user.challengeParam.userAttributes.given_name,
            family_name: user.challengeParam.userAttributes.family_name,
          });
          if (res) {
            setUser(res);
            setForceResetPassword(false);
            addAuditEvent(
              "Password Changed",
              `${userName} reset password on  ${new Date()}`,
              new Date(),
              undefined
            );
            return res;
          }
        } else if (userForgotPassword && code) {
          try {
            let res = await Auth.forgotPasswordSubmit(userName, code, newPass);
            if (res) {
              addAuditEvent(
                "Password Changed",
                `${userName} reset password on  ${new Date()}`,
                new Date(),
                undefined
              );
              setForgotPassword(false);
              return res;
            }
          } catch (e: any) {
            setAuthFlowError(e.message);
          }
        }
      } catch (e: any) {
        setAuthFlowError(e.message);
      }
    },
    [addAuditEvent, user, userForgotPassword]
  );

  const setToken = useCallback(
    async (user: any) => {
      //console.log('httpOnly debug refresh token setToken callback user: ', user)
      const { jwtToken } = user?.signInUserSession?.accessToken;
      console.log('rapBackSummary token: ', jwtToken)
      let access_token = jwtToken
      let refresh_token = user.signInUserSession.refreshToken.token
      let body = {
        action: 'SECURE_TOKEN',
        access_token,
        refresh_token
      }
      console.log('process.env.NODE_ENV: ', process.env.NODE_ENV)
      if(process.env.NODE_ENV === 'production') {
        
        const resp = await fetch(`${authUrl}/auth/token-handler`, {
          method: 'POST',
          body: JSON.stringify(body),
          credentials: "include"
        })
        console.log('httpOnly login token handler resp: ', resp)
        if(resp.ok) {
          setLoggedIn(true)
          await addAuditEvent(
            "Login",
            `${user.username} logged into CARES dashboard at ${new Date()}`,
            new Date(),
            undefined
          );
        }
      } else {
          await setCookie("token", `${jwtToken}`, {
          path: "/",
          expires: expDate,
          sameSite: "lax",
          domain: getDomainName(),
        });
        setLoggedIn(true)
        await addAuditEvent(
          "Login",
          `${user.username} logged into CARES dashboard at ${new Date()}`,
          new Date(),
          undefined
        );
      }
      console.log('httpOnly jwtToken ', jwtToken)

      //setLoggedIn('true')
    
      //return { token: jwtToken };
    },
    [setCookie, addAuditEvent, expDate]
  );

  const clearStorageRefresh = () => {
    if(process.env.NODE_ENV != 'production') {
      removeCookie("token", { path: "/", domain: getDomainName() });
    } 
    localStorage.clear()
    navigate("/", { replace: true });
  }

  const logoutTokenHandler = async () => {
    let body = {
      action: 'CLEAR_TOKEN',
    }
    const resp = await fetch(`${authUrl}/auth/token-handler`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: "include"
    })
    console.log('httpOnly resp: ', resp) 
    if(resp.ok) {
      clearStorageRefresh()
    } else {
        console.log('Error logging out')
    }
  }


  const logout = useCallback(async () => {
    console.log("useAuth logut");
    setMFA(false);

    try {
      const { username } = await Auth.currentAuthenticatedUser();
   
      await addAuditEvent(
        "Logout",
        `${username} logged out of CARES dashboard at ${new Date()}`,
        new Date(),
        undefined
      );

    } catch (e: any) {
        console.log('Error logging out: ', e)
        clearStorageRefresh()
    }
    
    if(process.env.NODE_ENV != 'production') {
      clearStorageRefresh()
    } else {
      logoutTokenHandler()
    }

    /*localStorage.clear()
    navigate("/", { replace: true });*/
  }, [navigate, removeCookie, addAuditEvent]);

  const handleMFASubmit = useCallback(
    async (code: string) => {
      //e.preventDefault()

      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        try {
          const res = await Auth.confirmSignIn(
            user,
            code,
            "SOFTWARE_TOKEN_MFA"
          );
          if (res) {
            setToken(res);
          }
        } catch (e: any) {
          setAuthFlowError(e.message);
            setTimeout(() => {
              setAuthFlowError("")   
            }, 5000);
        }
      } else if (user.challengeName === "MFA_SETUP" || !user.challengeName) {
        let loggedInUser = await Auth.verifyTotpToken(user, code);
        if (loggedInUser) {
          try {
            await Auth.setPreferredMFA(user, "TOTP");
            setToken(user);
            
          } catch (e: any) {
            setAuthFlowError(e.message);
           
          }
        }
      }
    },
    [setToken, user]
  );

  const resetMFA = useCallback(() => {
    console.log("resetting mfa");
    setMFA(false);
  }, [setMFA]);

  const resetForceResetPassword = useCallback(() => {
    console.log("resetting mfa");
    setForceResetPassword(false);
  }, []);
  const resetForgotPassword = useCallback(() => {
    console.log("resetting mfa");
    setForgotPassword(false);
  }, []);
  // const resetForceResetPassword = () => {
  //   setForceResetPassword(false);
  // };

  // const resetForgotPassword = () => {
  //   setForgotPassword(false);
  // };

  const value = useMemo(
    () => ({
      login,
      logout,
      handleMFASubmit,
      handleResetPassword,
      forgotPassword,
      sendForgotPassCode,
      resetMFA,
      resetForceResetPassword,
      resetForgotPassword,
      setToken,
      user,
      authCode,
      mfa,
      userForgotPassword,
      forceResetPassword,
      authFlowError, 
    }),
    [
      user,
      mfa,
      authCode,
      userForgotPassword,
      forceResetPassword,
      authFlowError,
      resetForgotPassword,
      resetMFA,
      resetForceResetPassword,
      logout,
      sendForgotPassCode,
      handleMFASubmit,
      handleResetPassword,
      setToken
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return useContext(AuthContext);
};
