/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import {
  useEditor,
  EditorContent,
  BubbleMenu,
  Editor,
  Extension,
} from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import { Color } from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import {
  HighlightFilled,
  HighlightOutlined,
  PrinterOutlined,
  SaveFilled,
} from "@ant-design/icons";
import  TextStyle from "@tiptap/extension-text-style";
import { Redact } from "./marks";
import { Button } from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import printText from "utils/printText";
import NotesService from "utils/notesService";
import { EditView } from "components/EditView/EditView";
import { useAudit } from "../../../../hooks/useAudit";
import { urls } from "../../../../urls";
import he from "he";

interface CustomEditorProps {
  originalText: string;
  isEditable?: boolean;
  canPrint?: boolean;
  isVisible?: boolean;
  tcn: any;
  redactedJSON?: any;
}

const getMarkPositions = (editor: Editor | null) => {
  const doc = editor?.view.state.doc;
  const marks: { start: number; end: number }[] = [];
  doc?.nodesBetween(0, doc.content.size, (node, pos) => {
    node.marks.forEach((mark) => {
      if (mark.type.name === "highlight") {
        marks.push({
          start: pos,
          end: pos + node.nodeSize,
        });
      }
    });
  });
  return marks;
};

const getSelectionTextContent = (
  editor: Editor | null,
  { start, end }: { start: number; end: number }
) => {
  if (!editor) return "";
  return editor?.state.doc.textBetween(start, end);
};

const CustomEditor: React.FC<CustomEditorProps> = ({
  originalText,
  redactedJSON,
  isVisible = true,
  canPrint = false,
  tcn,
  isEditable = true,
}) => {
  const editor = useEditor({
    onUpdate({ editor }) {
      console.log("editor.getJSON", editor?.getHTML());
      const sanitizeText = (text: string) => {
        const sanitizedInput = text.replace(/[&<>"']/g, "");
        const encodedText = he.encode(sanitizedInput);
        return encodedText;
      };
      sanitizeText(editor?.getHTML());
    },
    extensions: [
      StarterKit,
      Highlight.configure({ multicolor: true }),
      Color.configure({
        types: ["textStyle"],
      }),
      TextStyle,
      Redact,
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editable: isEditable,
  });

  const url = urls.NOTES;
  const { registrationId = "" } = useParams();
  const { addAuditEvent } = useAudit();

  let notesService = new NotesService(registrationId, "redact", url);

  useEffect(() => {
    notesService = new NotesService(registrationId, "redact", url);
  }, [url]);

  const saveRedactedText = () => {
    editor
      ?.chain()
      .focus()
      .selectAll()
      .updateAttributes("highlight", { color: "black" })
      .run();

    const redactions = getMarkPositions(editor);
    editor?.commands.forEach(redactions, ({ start, end }, { chain }) => {
      const range = { from: start, to: end };

      chain()
        .setTextSelection(range)
        .unsetAllMarks()
        //@ts-ignore
        .setRedaction({ color: "black" })
        .insertContentAt(
          range,
          getSelectionTextContent(editor, { start, end }).replace(/\S/g, "█")
        )
        .run();

      return true;
    });

    const hadRedaction = !!redactedJSON.length;

    hadRedaction
      ? notesService.updateNote(redactedJSON[0].id, editor?.getHTML() ?? "")
      : notesService.createNote(editor?.getHTML() ?? "");

    addAuditEvent("Create Redaction", "TCN: " + tcn + " Redacted: " +  JSON.stringify(redactedJSON), new Date(), undefined, undefined)
  };

  const printRedactedText = () => {
    printText(editor?.getHTML() ?? "", "", "", {
      printBackground: false,
    })
    addAuditEvent("Print Redaction", "Print data for: " + tcn, new Date(), undefined, undefined)
  };

  const editorObjectRef = useRef<Editor | null>(null);
  editorObjectRef.current = editor;

  useEffect(() => {
    if (!originalText || !editor) return;
    editor.commands.setContent(originalText, true, {
      preserveWhitespace: "full",
    });
    console.log("debugging original text", originalText);
  }, [originalText, editor]);

  if (!isVisible)
    return (
      <div
        style={{ backgroundColor: "#fff", height: "calc(100% - 42px)" }}
      ></div>
    );

  console.log('debug CustomEditor BubbleMenu: ,', { TextStyle, editor })

  return (
    <>
      <div style={{ height: "calc(100% - 42px)" }}>
        {editor && (
          <div>
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <button
              // @ts-ignore
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .setHighlight({ color: "gray" })
                  .setColor("gray")
                  .run()
              }
              title="Redact"
            >
              <HighlightFilled />
            </button>
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .unsetMark("textStyle")
                  .unsetMark("highlight")
                  .run()
              }
              title="Clear Redaction"
            >
              <HighlightOutlined />
            </button>
          </BubbleMenu>
          </div>
        )}

        <EditorContent editor={editor} />
        {
          <div className="editor-overlay">
            {isEditable && (
              <Button
                title="Save Redacted Text"
                type="primary"
                className="editor-overlay__button save-button"
                onClick={saveRedactedText}
              >
                <SaveFilled style={{ fontSize: 20 }} />
              </Button>
            )}
            {canPrint && (
              <Button
                title="Print Redacted Text"
                type="primary"
                className="editor-overlay__button"
                onClick={printRedactedText}
              >
                <EditView>
                  <PrinterOutlined style={{ fontSize: 20 }} />
                </EditView>
                Print
              </Button>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default CustomEditor;
